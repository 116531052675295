import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const ZonesCheckboxes = ({ handleZoneAdds, selectedZones, zones }) => {
  return (
    <div name="zone_checkboxes" className="zone_checkboxes_container">
      {zones
        .filter((zone) => zone.active)
        .map((zone) => {
          return (
            <div key={zone.id}>
              <input
                type="checkbox"
                key={zone.id}
                name={zone.id}
                className="checkbox"
                value={zone.id}
                onChange={handleZoneAdds}
                checked={selectedZones.includes(`${zone.id}`)}
              />
              <div className="zone_name">{zone.name}</div>
            </div>
          );
        })}
    </div>
  );
};

ZonesCheckboxes.propTypes = {
  handleZoneAdds: PropTypes.func.isRequired,
  selectedZones: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
};

export default ZonesCheckboxes;
