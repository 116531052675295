/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../Components/Loader';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { createOpsUser } from '../../api';
import ZonesCheckboxes from '../../Components/ZonesCheckboxes';

import './add.scss';

const AddUser = ({ zones }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loaded, setLoaded] = useState(true);
  const [userZones, setUserZones] = useState([]);
  const [response, setResponse] = useState({});

  useEffect(() => {
    setDisabled(
      firstName.length === 0 ||
        lastName.length === 0 ||
        email.length === 0 ||
        phone.length !== 10 ||
        userZones.length === 0
    );
  }, [firstName, lastName, email, phone, userZones]);

  const resetForm = () => {
    setDisabled(false);
    setLoaded(true);
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setUserZones([]);
  };

  const onSubmitUser = (event) => {
    setResponse({});
    setDisabled(true);
    setLoaded(false);
    event.preventDefault();
    const params = {
      country_code: 1,
      first_name: firstName.length > 0 ? firstName : undefined,
      last_name: lastName.length > 0 ? lastName : undefined,
      phone,
      email: email.length > 0 ? email : undefined,
      zone_ids: userZones,
    };
    createOpsUser(params)
      .then(([res]) => {
        resetForm();
        setResponse(res);
      })
      .catch(() => {});
  };

  const handleZoneAdds = (event) => {
    const { checked, name } = event.target;
    const newZones = checked
      ? [...userZones, name]
      : userZones.filter((userZone) => userZone !== name);
    setUserZones(newZones);
  };

  const onChangePhone = (event) => {
    let newPhone = event.target.value;
    newPhone = newPhone.replace(/\D/g, '').substring(0, 10);
    setPhone(newPhone);
  };

  const feedback = () => {
    if (response && response.id) {
      return (
        <div className="form_input_section">
          <p className="feedback_success">Ops user created</p>
        </div>
      );
    }
  };

  return (
    <AuthenticatedPage>
      <div className="page-title">Add Ops User</div>
      <p>All fields are required. Ops users must be assigned to at least one zone.</p>
      <form onSubmit={onSubmitUser} style={{ marginTop: '30px' }}>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="first_name">
            First Name
          </label>
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            className="input_names"
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="last_name">
            Last Name
          </label>
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            className="input_names"
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="phone">
            Phone Number
            <br />
            (10 digits, required)
          </label>
          <input
            type="numeric"
            name="phone"
            placeholder="Phone Number"
            value={phone}
            onChange={onChangePhone}
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="email">
            Email Address
          </label>
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="form_input_section">
          <label className="form_input_section__label" htmlFor="email">
            Zones
          </label>
          <ZonesCheckboxes
            zones={zones}
            handleZoneAdds={handleZoneAdds}
            selectedZones={userZones}
          />
        </div>
        <div className="form_input_section">
          <input type="submit" value="Submit" disabled={disabled} />
          <input type="button" value="Clear" onClick={resetForm} />
        </div>
      </form>
      {feedback()}
      <Loader loaded={loaded} />
    </AuthenticatedPage>
  );
};

AddUser.propTypes = {
  actions: PropTypes.object,
  zones: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    zones: { zones },
  } = state;
  return {
    zones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
